// Core variables and mixins
$screen-xxs-min: 320px;
$screen-xxs-max: ($screen-sm-min - 1);
$celanio-blue: #158fd0;

//komatsu variable
$primary-color: #092a72;
$secondary-color: #495057;
$additional-color: #495057;

$gray-1-color:#878787;
$gray-2-color:#b3b2b2;
$gray-3-color:#dadada;

//$secondary-color: #FFF;

//need to define variable
$additional-color: #ddee63;
$additional-color-hover: #CDE617;

$gray-1-color:#878787;
$gray-2-color:#9d9d9c;
$gray-3-color:#F2F7FB;

$main-font: "Helvetica Neue", Helvetica, Arial, sans-serif;
$header-font: 'Montserrat', "Helvetica Neue", Helvetica, Arial, sans-serif;
$headersmall-font: "Helvetica Neue", Helvetica, Arial, sans-serif;
$navbutton-font: "Helvetica Neue", Helvetica, Arial, sans-serif;

//header variable
$header-background-color:$primary-color;
$header-p-color:#FFF;
$header-a-color:#FFF;
$header-h-color:#FFF;

//footer variable
$footer-background-color:$primary-color;
$footer-p-color:#FFF;
$footer-a-color:#FFF;
$footer-h-color:#FFF;

// Path to directory with Montserrat font files
$montserrat-font-path: '../fonts' !default;