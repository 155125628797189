@font-face {
  font-family: 'Montserrat';
  src: url('#{$montserrat-font-path}/montserrat-bold-webfont.eot');
  src: url('#{$montserrat-font-path}/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('#{$montserrat-font-path}/montserrat-bold-webfont.woff2') format('woff2'),
       url('#{$montserrat-font-path}/montserrat-bold-webfont.woff') format('woff'),
       url('#{$montserrat-font-path}/montserrat-bold-webfont.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
