/*Responsive*/
/* Small devices (mobilephone, 320px and up) */
@media (min-width: $screen-xxs-min) and (max-width: $screen-xxs-max){
  #topnavaccordion .navbar-nav {
    float: none;
    padding-left: 15px;
    padding-right: 15px;
  }
  .container .cel-header{
    padding: 0;
  }
  .cel-header .navbar .navbar-nav{
    padding-top: 0;
    padding-bottom: 10px;
  }
  .cel-header .navbar-default .navbar-collapse {
    text-align: left;
  }
  ul.cel-menuhide{
    display: none;
    border-bottom: 1px dashed #fff;
  }
  nav.navbar .navbar-collapse {
    padding-top: 12px;
  }
  .cel-logo img{
    width: 150px;
  }
  #topnavaccordion a.head{
    color: #495057;
    &:hover, &:active,&:focus{
      text-decoration: none;
      color: #495057;
      background-color: transparent;
    }
  }
  nav.navbar {
    position: relative;
    width: 100%;
    .navbar-collapse {
      position: absolute;
      right: 0;
      width: 100%;
      z-index: 100;
      background-color: #FFF;
      a {
        color: #495057;
      }
    }
  }

  .navbar-collapse{
    position: relative;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #FFF;
  }
  .cel-input-wrapper .input-group{
    padding-bottom: 10px;
  }
  .form-horizontal .control-label{
    text-align: left;
  }
  footer.cel-footer-wrapper .cel-subfooter{
    padding-left: 30px;
  }
  .cel-header .navbar .navbar-nav>li>a:after {
    content: "";
  }
  #topnavaccordion li>div, #navaccordion li>div {
    padding: 0;
  }

  #accordion{
    .panel-body {
      .cel-infoline-button {
        float: none;
        display: block;
        text-align: left;
        padding-bottom: 10px;
      }
      .cel-infoline-button:last-child {
        float: none;
        display: block;
        padding-bottom: 0;
      }
    }
  }
  .cel-input-wrapper{
    .cel-w80,
    .cel-w50,
    .cel-w20 {
      margin-bottom: 10px;
    }
  }

  /*fix login switch*/
  #cel-webshop-switch {
    .row {
      display: block;
    }
    #cel-webshop-switch-register{
      border-top: 1px solid #949292;
      margin-top: 10px;
      padding-top: 10px;
    }
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .navbar-collapse{
    position: relative;
  }
  .cel-logo img{
    width: 150px;
  }
  nav.navbar .navbar-collapse {
    padding-top: 12px;
  }
  .navbar-collapse.collapse.cel-sidebar-on{
    display: none !important;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #FFF;
  }
  .form-horizontal .control-label{
    text-align: left;
  }
  #topnavaccordion {
    .panel-collapse {
      position: absolute;
      left: 0;
      background-color: white;
      padding: 10px;
      top: 44px;
      min-width: 100%;
      box-sizing: content-box;
      box-shadow: 0px 5px 20px 0px lightgray;
      li {
        display: block;
      }
    }
  }

  @media (min-width: 768px){
    .cel-five-col .col-sm-2 {
      width: 20%;
    }
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) {
  .container .cel-header{
    padding: 0;
  }
  .navbar-collapse.collapse.cel-sidebar-on{
    display: none !important;
  }
  .form-horizontal .control-label{
    text-align: left;
  }
  #topnavaccordion {
    .panel-collapse {
      position: absolute;
      left: 0;
      background-color: white;
      padding: 10px;
      top: 44px;
      min-width: 100%;
      box-sizing: content-box;
      box-shadow: 0px 5px 20px 0px lightgray;
      li {
        display: block;
      }
    }
  }

  @media (min-width: 992px){
    .cel-five-col .col-md-2 {
      width: 20%;
    }
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) {
  @media (min-width: 1200px){
    .cel-five-col .col-lg-2 {
      width: 20%;
    }
  }
}