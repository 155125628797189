#cel-event-details{
  .table > thead > tr > th,
  .table > thead > tr > td,
  .table > tbody > tr > th,
  .table > tbody > tr > td,
  .table > tfoot > tr > th,
  .table > tfoot > tr > td {
    border: none;
  }
  tr{
    th,
    td:first-child{
      padding-left: 0;
    }
  }
  .separator{
    display:none;
  }
}

#cel-event-details {

  .panel-default > .panel-heading {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
  }
  h4{
    font-size: 16px;
  }
  .panel-default {
    border-color: #ddd;
  }
  .cel-button-group{
    position: absolute;
    bottom: 24px;
    right: 24px;
  }
  .panel{
    height: 100%;
    padding-bottom: 30px;
    border-color: #ddd;
  }
  .panel-body{
    padding-right: 15px;
  }
  .row-eq-height {
    display:         flex;
  }
  hr{
    border-color: #ddd;
    margin: 10px 0;
  }
}

#cel-event-pdfs {
  li{
    padding-bottom: 4px;
    list-style-type: none;
  }
  ul{
    padding-left: 0px;
  }
}