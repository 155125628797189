/* Modul 011400 */

#text-before,
#text-after {
    margin: 1em 0;
}

#cel-letter-search-bar {
    text-align: center;
    margin: 1em 0;

    a {
        padding: 5px 10px;
        border-radius: 3px;
        &.letter {
            text-transform: uppercase;
        }
    }
}

.cel-contact-item {
    padding: 1em;

    &.expanded .details {
        display: block !important;
    }

    .link a {
        color: transparent;
    }

    .details-link {
        display: none;

        a {
            cursor: pointer;
        }
    }

    &:hover {
        .link a {
            color: #337ab7;
        }

        .details-link {
            display: block;
        }
    }

    .item-inner {
        overflow: hidden;
        background-color: #eee;
        padding: .75em;

        > div {
            float: left;
        }

        .image {
            width: 40%;
            padding-top: 48%;
            background-size: contain;
            background-repeat: no-repeat;
            .img-responsive {
                width: 100%;
                position: absolute;
                top: 0;
            }
        }

        .data {
            width: 58%;
            padding: 0 1em;

            .salutation {
                display: none;
            }
        }

        .details {
            display: none;
            position: absolute;
            background-color: #eee;
            border: 1px solid #ddd;
            margin: 0 -1em;
            padding: 0 1em 1em;
            z-index: 2;
            width: 84%;

            & > div {
                margin-top: .75em;
            }
        }
    }
}

#cel-load-more-wrapper {
    padding: 2em;
    text-align: center;
}
