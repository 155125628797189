.tiles {
	margin: 1em 0;
	a {
		display: inline-block;
		margin: 1.5em 1.5em 0 0;

		.tile {
			display: inline-block;
			width: 240px;
			height: 240px;
			.name {
				text-align: center;
				padding: 0.5em;
			}
		}
	}
}

.cel-category-image,
.cel-target-group-image {
	width: 100%;
	height: 100%;
}

.no-image {
	background-color: #ddd;
}
