/*Footer*/
footer.cel-footer-wrapper{
  background-color:#f7f7f7;
  border-top:1px solid #ddd;
  .cel-footer-inner-wrapper {
    background-color:transparent;
    padding: 30px 0;
  }
  li{
    display: block;
  }
  .cel-footer{
    text-align: right;
  }
  .wpex-info-widget .fa {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 1.231em;
  }
  .wpex-info-widget div {
    padding-left: 2.308em;
    position: relative;
    line-height: 1.5;
    margin-bottom: 10px;
  }
}

.cel-subfooter{
  background-color:#dee2e6;
  text-align: left;
  padding: 16px 0;
  .container{
    color: #343a40;
    background-color: transparent;
  }
  &:after {
    content: " ";
    display: table;
    clear: both;
  }
  .cel-footer-menu-wrap{
    padding: 16px 16px 16px 0;
    float: right;
    .cel-footermenu{
      float: left;
      padding: 0 16px;
      &:before{
        display: inline-block;
        font: normal normal normal 12px/1 FontAwesome;
        font-size: inherit;
        content: "\f105\00a0";
      }
      a{
        color:#495057;
        &:hover, &:active,&:focus{
          color:#495057;
          background-color: transparent;
        }
      }
    }
  }
  p{
    color:#495057;
    margin:0;
    padding: 16px 16px 16px 0;
  }
  ul li{
    float: left;
    a {
      text-decoration: none;
    }
  }
}