.cel-070100{

  //colors
  $color_gallery_approx: #eeeeee;

  .cel-border {
    border: 1px solid $color_gallery_approx;
    border-radius: 10px;
  }

  textarea{
    max-width: 100%;
  }

  .cel-bottom-margin{
    margin-bottom: 10px;
  }

  .cel-top-margin{
    margin-top: 10px;
  }
}