.cel-banner{
  max-height:471px;
  overflow: hidden;
}

.komatsu-logo {
  width: 192px;
  height: 37px;
}

.cel-logo a{
  margin: 16px 16px 16px 0;
  padding: 12px 0;
  .logo-subline{
    font-family: $header-font;
    font-size: 15px;
    font-weight: 900;
    color: #6c757d;
    padding-top: 15px;
    padding-bottom: 5px;
  }
}

.cel-header{
  font-family: $header-font;
  background-color: $primary-color;
  font-size: 18px;
  font-weight: bolder;
  .container, .container-fluid{
    background-color:transparent;
    color: #FFF;
  }
  .navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:hover, .navbar-default .navbar-nav>.active>a:focus{
    background-color:transparent;
  }
  .navbar-collapse{
    padding-left: 0;
    padding-right: 0;
  }
  .navbar{
    padding-top: 8px;
    min-height: 60px;
  }
}
.cel-logo-wrap{
  background-color: #e9ecef;
  .container, .container-fluid{
    background-color: #e9ecef;
  }
}
.cel-logo {
  position: relative;
  .cel-user-links {
    position: absolute;
    top: 0;
    right: 0;
    li{
      display: inline-block;
      a{
        padding: 0 0 0 16px;
        font-size: 16px;
        text-decoration:none;
        color:$secondary-color;
      }
    }
  }
}

body{
  font-size: 16px;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $header-font;
  font-weight: 800;
  color: #092a72;
}

h1,.h1{
  font-size: 39px;
}

h2,.h2{
  font-size: 35px;
}

/*Responsive*/
/* Small devices (mobilephone, 320px and up) */
@media (min-width: $screen-xxs-min) and (max-width: $screen-xxs-max){
  #topnavaccordion,
  .cel-logo-wrap,
  .cel-subfooter {
    padding-left: 30px;
    padding-right: 30px;
  }
  .navbar-toggle{
    margin-right: 30px;
  }
  .cel-header .navbar-header{
    margin: 0;
  }
  .cel-subfooter .cel-footer-menu-wrap{
    float: left;
  }
  .container, .container-fluid{
    overflow-x: initial;
  }
  .cel-logo .cel-user-links{
    position: relative;
  }
  ul.cel-user-links{
    padding: 0;
  }
  .cel-logo .cel-user-links li a{
    margin: 10px;
    padding: 0;
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  #topnavaccordion,
  .cel-logo-wrap,
  .cel-subfooter {
    padding-left: 30px;
    padding-right: 30px;
  }
  .cel-header .navbar-header{
    margin: 0;
  }
  .navbar-toggle{
    margin-right: 30px;
  }
  .cel-subfooter .cel-footer-menu-wrap{
    float: left;
  }
  .container, .container-fluid{
    overflow-x: initial;
  }
}
#bs-example-navbar-collapse-1{
  margin: 0;
}
/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) {
  .cel-logo-wrap,
  .cel-subfooter {
    padding-left: 0;
    padding-right: 0;
  }
  .container, .container-fluid{
    overflow-x: hidden;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) {

}