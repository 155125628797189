/* 012200 Module Styles */

#pcc_pagination_table .panel-body, #cel-catalog-page .panel-body {
  padding: 15px 15px 15px 15px;
}

.cel-catalog-margin-up{
  margin-top: 15px;
}

#cel-catalog-selection h1 {
  font-size: 24px;
}

#cel-catalog-selection .cel-submit {
  margin: 16px 0;
}

#cel-catalog-page h1 {
  font-size: 24px;
}

#cel-catalog-page #cel_productcatalog_container .catDesc {
  padding-bottom: 16px;
}

#cel-catalog-category-page h1 {
  font-size: 24px;
}

#cel-catalog-category-page #basket {
  padding: 8px;
  background-color: #eee;
  margin: 16px 0 8px;
  border: 1px solid #ccc;
}

#cel-catalog-category-page #basketDdl {
  margin: 16px 0;
}

#cel-catalog-category-page #cel_prodcatcategory_container {
  margin: 16px 0;
}

#cel-catalog-category-page #cel_prodcatcategory_container tr {
  vertical-align: top;
}

#cel-catalog-category-page #cel_prodcatcategory_container .price td {
  padding: 10px 0 22px;
}

#cel-catalog-category-page #cel_prodcatcategory_container .catName {
  font-size: 16px;
}

#cel-catalog-category-page #cel_prodcatcategory_container .catDesc {
  display: none;
}

#cel-catalog-category-page #cel_prodcatcategory_container .matPrice {
  font-weight: bold;
}

#cel-catalog-category-page .cel-buttons-left {
  float: left;
}

#cel-catalog-category-page .cel-buttons-right {
  text-align: right;
}

#cel-resource-booking-calendar h1 {
  font-size: 24px;
}

#cel-resource-booking-calendar h2 {
  font-size: 18px;
}

.ui-dialog .ui-dialog-titlebar-close {
  background-color: transparent;
  border: 0;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
}

.ui-dialog #cel-time-pick-dialog #cel-time-slots a {
  cursor: pointer;
  color: #2B9CDD;
}

.ui-dialog .ui-dialog-buttonpane {
  display: none;
}

#cel-resource-booking-calendar #cel-calendar-wrapper {
  margin-top: 32px;
}

#cel-resource-booking-calendar #cel-calendar-wrapper .fc-event-container {
  cursor: pointer;
}

#cel-resource-booking-calendar #cel-calendar-wrapper .fc-event {
  background-color: #2B9CDD;
}

.col_action_delete i {
  font-size:1.5em;
}
.input_article_amount {
  max-width: 64px;
}

.article_list_header th {
  padding-bottom: 12px;
}

.article_list {
  margin-bottom: 16px;
}

.article_list .row_price_total td {
  font-weight: bold;
}

.article_list .row_price_total {
  border-top: 1px solid #ccc;
}

.article_list .row_article td {
  padding-bottom: 8px;
}

#invoice_recipient_source_form .line.headline {
  font-weight: bold;
}

#invoice_recipient_source_form .line .line {
  padding: 0;
}

#invoice_recipient_source_form .text.email {
  width: 256px;
}

#invoice_recipient_source_form label {
  font-weight: normal;
  padding-top: 5px;
}

#cel_prodcatcategory_container{
  nav{
    text-align: center;
  }
}

#showBasket{
  a {
    display: inline-block;
    font-size: 20px;
  }
}

.description .cel-overflow{
  overflow: hidden;
  max-height: 4.5em;
  min-height: 4.5em;
  font-size: 1em;
  line-height: 1.5em;
}

#pcc_pagination_table {
  .collapsed {
    .cel-more,
    .cel-plus {
      display: inline-block;
    }

    .cel-less,
    .cel-minus {
      display: none;
    }

    .cel-minus, .cel-plus {
      background-color: #247dc4;
    }
  }

  .cel-minus,
  .cel-plus {
    display: inline-block;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .cel-plus {
    width: 42px;
    color: #fff;
    background-color: #838383;
    display: none;
  }

  .cel-more {
    display: none;
  }

  .cel-minus {
    width: 42px;
    color: #fff;
    background-color: #838383;
  }
}

.breadcrumb{
  i.fa{
    font-size: 20px;
  }
}